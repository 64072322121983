@font-face {
  font-family: "contisans";
  src: url('../../fonts/MYRIADPRO-REGULAR.OTF');
  /* src: url("../../fonts/ContiSans.otf"); */
}

@font-face {
  font-family: "myriad-pro-italic";
  src: url("../../fonts/MYRIADPRO-BOLDIT.OTF");
}

@font-face {
  font-family: "contisans-bold";
  src: url("../../fonts/MYRIADPRO-BOLD.OTF");
  /* src: url("../../fonts/ContiSans-Bold.otf"); */
}

@font-face {
  font-family: "contisans-light";
  src: url("../../fonts/MyriadPro-Light.otf");
}

.fnt-contisans {
  font-family: "contisans";
  /* font-size: 1.375rem; */
  font-size: clamp(1.375rem, 1.375rem, 1.7rem);
}

.fs-myriad {
  font-family: "contisans";
}

.fs-myriad-normal {
  font-size: clamp(1rem, 1.1rem, 1.5rem);
}

.fs-myriad-nav {
  font-size: clamp(0.9rem, 0.625rem + 0.5vw, 1.2rem);
}

.fnt-myriad-italic {
  font-family: "myriad-pro-italic";
}

.fnt-myriad-bold {
  font-family: "contisans-bold";
}

.fs-myriad-xl {
  font-size: clamp(1.375rem, 1.5rem, 1.7rem);
}
.fs-myriad-title-accordion {
  font-size: clamp(1.1rem, 0.5rem + 1vw, 1.7rem);
}

.fs-myriad-md {
  font-size: clamp(1rem, 1.1rem, 1.5rem);
}

.fs-myriad-small {
  font-size: clamp(0.8rem, 1rem, 1.2rem);
}

.fs-myriad-title {
  font-size: clamp(1rem, 1rem + 1vw, 1.7rem);
}

.fs-myriad-small-title {
  font-size: clamp(0.8rem, 1rem, 1.1rem);
}

.fs-myriad-directors {
  font-size: clamp(1.1rem, 0.8rem + 0.5vw, 1.5rem);
}

.fnt-contisans-advantages-title {
  font-family: "contisans";
  font-size: clamp(1.375rem, 1rem + 1vw, 2rem);
}

.fnt-contisans-title-footer {
  font-family: "contisans";
  font-size: 18px;
  font-weight: 800;
}

.fnt-contisans-footer {
  font-family: "contisans-light";
  font-size: 1rem;
}

.fnt-contisans-light {
  font-family: "contisans-light";
  font-size: 1.375rem;
}

.fnt-contisans-light-small {
  font-family: "contisans-light";
  /*  font-size: 1.1rem; */
  font-size: clamp(1.1rem, 1rem + 1vw, 1.2rem);
}

.fnt-contisans-light-title {
  font-family: "contisans-light";
  font-size: 2.625em;
}

.fnt-contisans-small {
  font-family: "contisans";
  /* font-size: 1rem; */
  font-size: clamp(1rem, 1.1rem, 1.5rem);
}

.fnt-contisans-title-carousel {
  font-family: "contisans";
  font-size: 2rem;
}

.fnt-contisans-title {
  font-family: "contisans";
  font-size: 2.1875rem;
  line-height: 1.2;
}

.fnt-contisans-appsection {
  font-family: "contisans";
  /* font-size: 2.1875em; */
  font-size: clamp(2.1875rem, 1rem + 3vw, 3.4375rem);
  line-height: 1.2;
}

.fnt-contisans-title-header {
  font-family: "contisans-light";
  /* font-size: 2.1875rem; */
  font-size: clamp(2.1875rem, 1rem + 3vw, 4rem);
}

.fnt-myriad-thin {
  font-family: "contisans-light";
}

.fnt-large {
  font-size: clamp(2.1875rem, 1rem + 2vw, 4rem);
}

.fnt-normal {
  font-size: clamp(1rem, 1rem + 1vw, 1.4rem);
}
