.menu--1 {
  z-index: 2000;
  position: absolute;
  right: calc(var(--container-header-footer-padding-right));
  /*  top: 0; */
}

#menu-navbar {
  display: none;
}

.lbl-hambur {
  display: block;
  cursor: pointer;
  width: 70px;
}

.menu--1 .lbl-hambur {
  bottom: 0;
}

.menu--1 .lbl-hambur {
  right: 0;
}

/* path {
  fill: none;
  stroke: #ffffff;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
} */

.hamburguer-stroke {
  fill: none;
  stroke: #ffffff;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

circle {
  /* fill: #a9c03c; */
  fill: transparent;
  opacity: 1;
}

#menu-navbar:checked ~ .cross .lbl-hambur svg .line--1,
#menu-navbar:checked ~ .cross .lbl-hambur svg .line--3 {
  --length: 22.627416998;
}

#menu-navbar:checked ~ .cross .lbl-hambur svg .line--2 {
  --length: 0;
}

#menu-navbar:checked ~ .back .lbl-hambur svg .line--1,
#menu-navbar:checked ~ .back .lbl-hambur svg .line--3 {
  --length: 8.602325267;
}


#menu-navbar:checked ~ .menu--1 .lbl-hambur svg .line--1,
#menu-navbar:checked ~ .menu--1 .lbl-hambur svg .line--3 {
  --offset: -94.1149185097;
}

#menu-navbar:checked ~ .menu--1 .lbl-hambur svg .line--2 {
  --offset: -50;
}

.menu--1 .line--1,
.menu--1 .line--3 {
  --total-length: 126.64183044433594;
}

.menu--1 .line--2 {
  --total-length: 70;
}


.nav-border-bottom {
  border-bottom: 1px solid #fff;
}

.text-wrap-balance {
  text-wrap: balance;
}

@media screen and (min-width: 75rem) {
  .nav-border-bottom {
    border-bottom: none;
  }

  .text-wrap-balance{
    text-wrap: nowrap;
  }
}

/* #menu-navbar:checked~.menu--1 {
    position: fixed;
    top: 8px;
} */
