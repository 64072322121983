.carousel-indicators-history {
  position: absolute;
  left: 50%;
  z-index: 10;
}

.carousel-control-prev,
.carousel-control-next {
  /* align-items: end;
  bottom: 30px; */
  width: auto;
}

.container-carousel-history

.card-carousel-img {
  max-height: 500px;
  /* min-height: 70vh; */
  background-size: cover;
  background-position: top;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  /* background-color: #000; */
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  display: inline-block;
  width: 40px;
  height: 100px;
}
.carousel-radius {
  overflow: hidden;
}

.container-history-text {
  font-size: 16px;
  margin-top: 0px;
}

.container-history {
  padding: 50px 0px;
}

.container-icons {
  position: absolute;
  top: 10px;
  right: 5%;
  z-index: 50;
}

.custom-icon {
  background: transparent;
  outline: none;
  border: none;
}

.icon-slider-prev,
.icon-slider-next {
  font-weight: 500;
  font-size: 35px;
  color: #cccccc;
  margin-right: 15px;
}

/* .img-carousel{
  max-width: 1280px;
  height: 100%;
} */

.img-fluid {
  height: 100%;
  object-fit: cover;
}

.icon-slider-prev:hover,
.icon-slider-next:hover {
  color: #fff;
}

@media screen and (min-width: 36em) and (orientation: landscape) {
  .container-carousel-history {
    height: 100%;
    padding: 10px 30px;
  }
}

@media screen and (min-width: 1025px) {
  .container-history {
    padding: 0px;
  }

  .container-icons {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 50;
  }

  .img-fluid {
    width: 100%;
  }

  .card-carousel-img {
    max-height: 500px;
    min-height: auto;
  }

  .container-history-text {
    margin-top: 50px;
  }

  .container-carousel-history {
    position: absolute;
    padding: 16px 40px;
    right: 0;
    width: 50%;
    height: 100%;
  }
}
