@import url("./modules/style-carousel.css");
@import url("./modules/style-appsection.css");
@import url("./modules/styles-new-fonts.css");

* {
  padding: 0;
  margin: 0;
}

body {
  font-size: 16px;
}

:root {
  --color-amarillo-nbc: #cedc00;
  --color-amarillo-nbc-hover: #c2cf03;
  --color-azul-nbc: #1d428a;
  --color-azul-nbc-opacity: rgba(29, 65, 138, 0.5);
  --color-gris-nbc: #f5f5f5;
  --color-azul-nbc-shadow: #1d428a;
  --carousel-color: #ebebeb;
  --color-azul-nbc-bright: #596fa6;
  --color-complementary-nbc: #cedc00; /* #55e9b4 */
  --btn-card-carousel-width: 50px;
  --btn-card-carousel-height: 50px;
  --container-header-footer-padding-right: 1em;
  --shadow-nav: #0000001a;
  font-size: 16px;
}

.insurance-page-info-section {
  .icon-container {
    width: 65px;
    height: 65px;
    margin: 0 auto 50px;
  }
}

.insurance-page-insurance-section {
  .icon-container {
    width: 65px;
    height: 65px;
  }

  .grid-cards {
    display: grid;
    grid-template-columns: repeat(4, 240px);
    justify-content: center;
    gap: 30px;
    padding: 20px;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 240px);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 240px);
    }
  }

  .item-card {
    width: 240px;
    height: auto;

    background: white;
    padding: 25px 15px 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    p {
      font-size: 0.785rem;
    }
  }
}

.insurance-page-assistance-and-benefits-container {
  display: grid;
  grid-template-columns: 920px 1fr;

  @media (max-width: 1550px) {
    grid-template-columns: 1fr;
  }
}

.insurance-page-assistance-section {
  .icon-container {
    width: 65px;
    height: 65px;
  }

  .grid-cards {
    display: grid;
    grid-template-columns: repeat(3, 250px);
    justify-content: center;
    gap: 30px;
    padding: 20px;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 250px);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 250px);
    }
  }

  .item-card {
    width: 250px;
    height: 300px;

    background: white;
    padding: 25px 15px 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    p {
      font-size: 0.785rem;
    }

    span {
      font-size: 0.785rem;
      font-weight: bold;
    }
  }
}

.insurance-page-benefits-section {
  .icon-container {
    width: 65px;
    height: 65px;
  }

  .grid-cards {
    display: grid;
    grid-template-columns: repeat(2, 250px);
    justify-content: center;
    gap: 30px;
    padding: 20px;

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 250px);
    }
  }

  .item-card {
    width: 250px;
    height: 300px;

    background: white;
    padding: 25px 15px 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    p {
      font-size: 0.785rem;
    }
  }
}

.insurance-page-packs-of-assistance-section {
  .icon-container {
    width: 16px;
    height: 16px;

    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .grid-cards {
    display: grid;
    grid-template-columns: repeat(4, 375px);
    justify-content: center;
    row-gap: 40px;
    column-gap: 35px;
    padding: 20px;

    @media (max-width: 1700px) {
      column-gap: 30px;
    }

    @media (max-width: 1610px) {
      grid-template-columns: repeat(3, 375px);
    }

    @media (max-width: 1225px) {
      grid-template-columns: repeat(2, 375px);
    }

    @media (max-width: 815px) {
      grid-template-columns: repeat(1, 375px);
    }

    @media (max-width: 400px) {
      grid-template-columns: repeat(1, 350px);

      .pack-item-card {
        width: 350px;
      }
    }
  }

  .pack-item-card {
    width: 375px;
    height: auto;

    background: #eef0ef;
    padding: 25px 15px 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;

    h3 {
      align-self: center;
      font-size: 1.4rem;
    }

    .item-card {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.cotacao-dolar-calendar {
  background-color: #FFF;
  margin: 1.5rem auto;
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.cotacao-dolar-form-container {
  display: flex;
  gap: 2rem;
}

.cotacao-dolar-form {
  width: 12rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 0.875rem;
    color: #212529;
  }

  input {
    border: 0;
    background-color: #F7F7F7;
    padding: 0.875rem;
    border-radius: 8px;
    color: #212529;

    &:hover {
      outline: 1px solid #d6d6d6;
    }

    &:focus {
      outline: 1px solid #b3b3b3;
    }

    &.field-error {
      outline: 1px solid #951b29;
      color: #951b29;
    }
  }

  & input[type=date]::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.cotacao-dolar-form-error {
  overflow-wrap: break-word;
  font-size: 0.875rem;
  color: #951b29;
  height: 2.5rem;
}

.cotacao-dolar-actions {
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 1rem;

  button {
    cursor: pointer;
    background-color: var(--color-complementary-nbc);
    font-size: 0.875rem;
    color: #212529;

    padding: 0.5rem 1rem;
    border: none;
    border-radius: 32px;

    &:not(:disabled):hover {
      background-color: #B7C700;
    }

    &:disabled {
      cursor: default;
      opacity: 0.6;
    }
  }
}

.cotacao-dolar-info {
  font-size: 0.875rem;
  color: #212529;
  margin: auto 0;
}


.calendar-container {
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;

  border: 1px solid #F7F7F7;
  border-radius: 8px;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
}

.calendar-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 160%;
  margin: 0;
  color: #212529;
  
  span {
    color: #5B5B62; 
  }
}

.calendar-actions {
  display: flex;
  gap: 0.5rem;
  color: #212529;

  button {
    all: unset;
    cursor: pointer;
    line-height: 0;
    border-radius: 6px;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    &:hover {
      svg {
        fill: #FFF !important;
      }

      background-color: #212529;
    }
  }
}

.calendar-body {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  border-spacing: 0.25rem;
  table-layout: fixed;
  border-collapse: initial;

  thead th {
    color: #212529;
    font-weight: 500;
    font-size: 0.75rem;
    text-align: center;
  }
  
  tbody:before {
    content: '.';
    line-height: 0.75rem;
    display: block;
    color: #fff;
  }

  tbody td {
    box-sizing: border-box;
  }

}

.calendar-day {
  all: unset;
  width: 100%;
  aspect-ratio: 1 / 1;
  background: #F7F7F7;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  color: #212529;
  font-size: 0.75rem;

  &:disabled {
    background: inherit;
    cursor: default;
    opacity: 0.4;
  }

  &:not(:disabled):hover {
    background-color: #B3B3B3;
  }

  &.selected {
    background-color: #D6D6D6;
  }

  &.hoveredRange {
    background-color: #B3B3B3;
  }
}

.h-30rem {
  height: auto;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.container-figure-img {
  transform: translateX(-23%);
}

.container-governanza {
  width: 100%;
}

.icon-error {
  font-size: 5rem;
  color: rgb(178, 17, 14);
}

.numbers-modalidade {
  padding: 5px 15px;
  font-size: 20px;
  margin-right: 16px;
  color: #000;
  background-color: var(--color-amarillo-nbc);
  border-radius: 50%;
}

.glassmorphifims {
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.glassmorphifims-notice {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.credit-advantage-description {
  display: inline-block;
  color: #000;
  transform: translateX(-5%);
}

.credit-advantage-icon {
  display: inline-block;
  transform: translateX(-50%);
}

.img-icon-advantage {
  width: 50px;
  object-fit: cover;
}

.container-credit {
  border-left: 5px dotted var(--color-azul-nbc);
}

.container-credit:last-child {
  border-left: none;
}

.grid-fgts {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.border-bottom-title {
  display: none;
}

.gap-card {
  gap: 30px;
}

.gap-agencias {
  gap: 20px;
}

/* 
    Clases de uso genérico
*/
.alice-carousel__stage > li {
  padding: 0 0.5em;
}

.mt-100 {
  margin-top: 0px;
}

.mb-100 {
  margin-bottom: 50px;
}

.carousel {
  position: relative;
  top: -1px;
}

.btn-download {
  background-color: var(--color-azul-nbc);
  border: none;
  padding: 8px 20px;
  border-radius: 25px;
}

.alice-carousel .alice-carousel__prev-btn-item,
.alice-carousel .alice-carousel__next-btn-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.alice-carousel .alice-carousel__prev-btn-item,
.alice-carousel .alice-carousel__next-btn-item {
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.alice-carousel .alice-carousel__prev-btn-item:hover,
.alice-carousel .alice-carousel__next-btn-item:hover {
  background-color: var(--color-azul-nbc-bright);
}

.menu-transition-color {
  position: fixed !important;
  top: 0;
  z-index: 999;
  transition: background-color 0.2s linear;
}

.alice-carousel .alice-carousel__prev-btn-item {
  right: 70px;
}

.alice-carousel .alice-carousel__next-btn-item {
  right: 0;
}

/* We center the carousel buttons */
.alice-carousel .alice-carousel__prev-btn-item span,
.alice-carousel .alice-carousel__next-btn-item span {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 0 0;
}

/* Here, we change the carousel icons */
.alice-carousel .alice-carousel__prev-btn-item span::after {
  content: url("../img/carousel-left-arrow.png");
}

.alice-carousel .alice-carousel__next-btn-item span::after {
  content: url("../img/carousel-right-arrow.png");
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  background: var(--color-azul-nbc);
  width: var(--btn-card-carousel-width);
  height: var(--btn-card-carousel-height);
  border-radius: 50%;
}

.alice-carousel__prev-btn-item > *,
.alice-carousel__next-btn-item > * {
  color: white;
}

/* Careers carousel */
#careers-carousel .alice-carousel {
  padding: 0;
}

#careers-carousel .alice-carousel__wrapper {
  padding-left: 0;
  padding-right: 0;
}

/* Careers Carousel Buttons */
#careers-carousel .alice-carousel .alice-carousel__prev-btn-item,
#careers-carousel .alice-carousel .alice-carousel__next-btn-item {
  position: absolute;
  transition: all 0.3s ease-in-out;
  background: transparent;
  border: 1px solid white;
}

#careers-carousel .alice-carousel .alice-carousel__prev-btn-item {
  left: 30%;
}

#careers-carousel .alice-carousel .alice-carousel__next-btn-item {
  right: 30%;
}

/* Slides */
#careers-carousel .alice-carousel .alice-carousel__stage div {
  border-radius: 2em;
  max-width: 400px;
  margin: 0 auto;
  min-height: fit-content;
  max-height: 500px;
  color: white;
  transition: all 0.3s ease;
}

#careers-carousel .alice-carousel__stage-item.__active > div {
  background: white;
  color: var(--color-azul-nbc);
}

.container__warning {
  padding: 20px;
  border-radius: 25px;
  margin: 50px auto;
}

.stop-scroll {
  height: 100%;
  max-height: 100%;
  /* overflow: hidden; */
}

.oculto {
  display: none;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-0 {
  opacity: 0;
}

.text-amarillo-nbc {
  color: var(--color-amarillo-nbc);
}

.text-azul-nbc {
  color: var(--color-azul-nbc);
}

.text-blanco {
  color: #fff;
}

.background:nth-child(odd) {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
}

.background-history {
  /* padding: 100px 0px; */
  /* background-color: #f7f7f7;
  border: 1px solid #cccccc; */
  padding: 50px 0px;
  width: 100%;
}

.text-verde-limon {
  color: var(--color-complementary-nbc);
}

.fnt-icon-apps {
  font-size: 55px;
  color: white;
}

.text-verde-nbc {
  color: #acc248;
}

.text-oscuro {
  color: #35353c;
}

.text-gris {
  color: #5b5b62;
}

.font-gris-nuevo {
  font-size: 22px;
  color: #707070;
}

.text-gris-claro {
  color: #959598;
}

.text-blanco-opacity {
  color: #ffffffb3;
}

.text-columns {
  column-count: 1;
}

.text-justify {
  text-align: justify;
}

.fnt-palanquin {
  font-family: Palanquin;
}

/* .fnt-contisans {
  font-family: Palanquin;
  font-family: ContiSans, Bold;
  font-size: 42px;
  color: #000;
} */

.fnt-100 {
  font-weight: 100;
}

.fnt-300 {
  font-weight: 300;
}

.fnt-400 {
  font-weight: 400;
}

.fnt-500 {
  font-weight: 500;
}

.fnt-600 {
  font-weight: 600;
}

.fnt-700 {
  font-weight: 700;
}

.fnt-small {
  font-size: 0.85em;
}

.fnt-small-label {
  font-size: 0.5em;
  line-height: 1px !important;
}

.link {
  text-decoration: none;
}

.link:hover {
  color: var(--color-amarillo-nbc);
}

.parrafos {
  font-family: Palanquin;
  font-size: 16px;
}

.btn:hover {
  color: #e3e3e3;
}

.bg-carousel {
  background: var(--carousel-color);
}

.bg-careers-carousel {
  background-image: linear-gradient(90deg, #1d4389, #1f3365);
}

.bg-azul-opacidad {
  background-color: var(--color-azul-nbc-shadow);
}

.bg-form-incomplete {
  background-color: rgba(255, 0, 0, 0.2) !important;
  /* We use important to rewrite Bootstrap's styles */
}

.bg-oscuro-opacidad {
  background-color: rgb(92 87 122 / 50%);
}

.job-position-bg {
  background: #d6dae5;
}

.bg-azul-nbc-oscuro {
  background-color: var(--color-azul-nbc-shadow);
}

.bg-azul-nbc {
  background-color: var(--color-azul-nbc);
}

.bg-amarillo-nbc {
  background-color: var(--color-amarillo-nbc);
}

.bg-verde-limon {
  background-color: var(--color-complementary-nbc);
}

.bg-transparente {
  background-color: transparent;
}

.bg-blanco {
  background-color: white;
}

.bg-gris {
  background-color: var(--color-gris-nbc);
}

.bg-grey {
  background-color: #f7f7f7;
}

.card-sin-borde {
  border: none;
}

.min-h-65 {
  min-height: 0vh;
}

.accordion-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 25px 50px #00000029;
  border-radius: 0px 0px 15px 15px;
}

.card-shadow {
  padding: 0px 20px;
  padding-top: 25px;
  padding-bottom: 0px;
  word-break: normal;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 25px 50px #00000029;
  border-radius: 15px;
  border: 1px solid #f4f4f4;
}

.border-radius-card {
  border-radius: 15px;
}

.card-investimento {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* grid-template-columns: repeat(3, 1fr);
  width: 30%; */
}

.translate-card-cambio {
  transform: translate(0%, -12%);
}

.padding-card-cambio {
  padding: 30px;
}

.card-contato {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto-fit, 1fr));
}

.card-agencias {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.card-agencia-container {
  height: auto;
}

.translate-top {
  transform: translateY(0px);
}

.col-section-card {
  min-height: 350px;
  width: 50%;
}

.col-item-button-container {
  position: absolute;
  bottom: 25px;
  /* bottom: 10px; */
}

.dropdown-menu .dropdown-item:hover {
  background: var(--color-azul-nbc);
  color: white;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-icon {
  display: inline-block;
  margin-left: 10px;
  background-image: url(../img/ARROW.svg);
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
}

.dropdown-menu .dropdown-item:active {
  background: var(--color-amarillo-nbc);
}

.btn-radius {
  /* padding: 10px 40px; */
  padding: 8px 30px;
  border-radius: 25px;
  color: white;
  /* font-family: Palanquin; */
  font-weight: 500;
}

.btn-radius-amarillo {
  /* padding: 10px 40px; */
  padding: 8px 30px;
  border-radius: 25px;
  /* font-family: Palanquin; */
  font-weight: 500;
}

.btn-radius-azul {
  padding: 8px 30px;
  border-radius: 25px;
  color: var(--color-amarillo-nbc);
  /* font-family: Palanquin; */
  font-weight: 500;
}

.btn-radius:hover {
  background-color: var(--color-amarillo-nbc-hover);
}

.btn-app {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  /* padding: 15px 20px; */
  width: 200px;
  /* width: 150px; */
  cursor: pointer;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.btn-app img,
.btn-app i {
  width: 30px;
  font-size: 30px;
}

.btn-app small {
  font-size: 18px;
  cursor: pointer;
}

.btn-app label {
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

.container-apps {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.btn-banking {
  text-decoration: none;
  /* padding:10px 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-family: "contisans";
  border-radius: 25px;
}

.btn-warning-link {
  min-width: 180px;
  padding: 10px 0px;
  margin-top: 30px;
  border: none;
  border-radius: 25px;
}

.btn-contacto {
  color: white;
  border-radius: 25px;
  padding: 10px 20px;
  font-family: Palanquin;
  font-weight: 500;
  border: #fff 3px solid;
}
.carousel-slide {
  max-width: 1320px;
}

.btn-cookie {
  border: solid 1px var(--color-azul-nbc);
  color: var(--color-azul-nbc);
  font-weight: 500;
}

.btn-cookie:hover {
  border: solid 1px #fff;
  background-color: var(--color-azul-nbc);
  color: #fff;
  font-weight: 500;
}

.ver-mais {
  color: rgb(50, 53, 61);
  text-decoration: none;
}

.ver-mais:hover,
.ver-mais:focus {
  color: var(--color-azul-nbc);
  text-decoration: underline;
}

.lista-sin-estilo {
  list-style: none;
}

/*
    Header
*/

.header-imagen {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.section-home-image-01 {
  background-image: url(../img/medium-shot-man-woman-shaking-hands.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: 400px;
}

.scroll-up-btn {
  position: fixed;
  padding: 15px 20px;
  right: 0;
  margin-right: 5px;
  bottom: 50px;
  z-index: 1050;
  transition: 0.3s all ease-in-out;
}

.h-full {
  min-height: 100vh;
}

.header-mini-section {
  padding-top: 150px;
  padding-bottom: 50px;
}

.background-opacidad {
  background-color: #1332694f;
  min-height: 90vh;
}

.background-opacidad-oscuro {
  background-color: #133369d6;
  height: 100%;
}

.container-header-footer {
  padding: 0 var(--container-header-footer-padding-right);
  max-width: 1600px;
  position: relative;
}

.home-header-item {
  width: 100%;
  min-height: 730px;
}

.home-header-modal-card {
  position: relative;
}

.header-section-background {
  padding: 130px 0px;
}

.header .navbar {
  position: absolute;
  width: 100%;
  box-shadow: 0px 5px 20px var(--shadow-nav);
  /* height: 75px; */
}

.header .active {
  color: var(--color-amarillo-nbc) !important;
  text-decoration: underline;
}

.header .navbar-brand {
  z-index: 99;
}

.header .btn-navbar {
  padding: 8px 12px;
  background-color: var(--color-amarillo-nbc);
  border-radius: 3px;
  z-index: 99;
}

.header .navbar-logo {
  /*  width: 200px; */
  width: 150px;
}

.header .btn-navbar:focus {
  box-shadow: 0 0 3px 0px #43516a;
}

.header .navbar-items-container {
  /* right: 0; */
  /* width: 100%; */
  /* padding-top: 81px; */
  /* transform: translateX(-100%); */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between */
  /*  padding-top: 60px; */
  position: fixed;
  top: 65px;
  z-index: 999;
  padding: 20px;
  /* padding-top: 20px;
  padding-left: 20px; */
  left: 0;
  width: 70%;
  /*  height: 100vh; */
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.5s;
  transform: translateX(-150%);
  text-align: start;
  background-color: var(--color-azul-nbc-opacity);
  backdrop-filter: blur(5px); /* Valor de desenfoque */
  -webkit-backdrop-filter: blur(5px); /* Prefijo de proveedor para Safari */
}

.dropdown-menu {
  border: none;
  background-color: transparent;
}
/* 
.dropend > .dropdown-menu {
  background-color: #fff;
} */

.dropend > .dropdown-menu {
  padding: 0px;
  background-color: #fff;
  position: absolute;
  transform: translate3d(-20px, 0px, 0px) !important;
}

.dropend > .dropdown-menu > li > .dropdown-item {
  color: #000;
}
.dropend > .dropdown-menu > li > .dropdown-item:hover {
  color: #fff;
  background-color: var(--color-azul-nbc);
}

.dropdown-item {
  color: #fff;
}

#menu-navbar:checked ~ .navbar-items-container {
  transform: translateX(0%);
}

.header .nav-item {
  display: inline;
  transition: all 0.5s;
  transform: translateX(-150%);
}

.header .nav-item-icon {
  display: inline-block;
  width: 30px;
}

.icon-check {
  display: block;
  /* width: 30px; */
  min-width: 25px;
  margin-right: 10px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../img/icon-check.svg");
}

.icon-check-alerta {
  display: inline-block;
  width: 50px;
  margin-right: 10px;
  height: 23px;
  background-repeat: no-repeat;
  background-image: url("../img/icon-check.svg");
}

.icon-check-size {
  display: inline-block;
  width: 34px;
  background-size: contain;
  margin-right: 10px;
  height: 23px;
}

.icon-phone {
  display: inline-block;
  width: 30px;
  margin-right: 10px;
  height: 30px;
  background-repeat: no-repeat;
  background-image: url("../img/phone-portrait-outline.svg");
}

.icon-mail {
  display: inline-block;
  width: 40px;
  margin-right: 10px;
  height: 30px;
  background-repeat: no-repeat;
  background-image: url("../img/mail-outline.svg");
}

.icon-minus {
  display: inline-block;
  width: 23px;
  margin-right: 10px;
  height: 7px;
  background-repeat: no-repeat;
  background-image: url("../img/icon-minus.svg");
}

.icon {
  display: inline-block;
  margin-right: 10px;
}

.icon-location-agency {
  width: 19px;
  height: 23px;
}

.icon-phone-agency {
  width: 15px;
  height: 25px;
}

.icon-mail-agency {
  width: 23px;
  height: 20px;
}

.icon-calendar-agency {
  /* width: 23px; */
  height: 20px;
}

.header .navbar .nav-item a.nav-link:hover,
.header .navbar .nav-item a.nav-link:focus,
.header .navbar .nav-item span.nav-link:hover,
.header .navbar .nav-item span.nav-link:focus {
  color: var(--color-amarillo-nbc);
  text-decoration: none;
  cursor: pointer;
}

#menu-navbar:checked ~ .navbar-items-container .nav-item {
  transform: translateX(0%);
}

.about-us-header-section {
  padding: 100px 0;
}

.about-us-header-section-inner {
  padding: 50px 0;
}

.header-section {
  padding: 150px 0px;
  /* padding-bottom: 200px; */
}

.header-titulo {
  font-family: Palanquin;
  font-size: 35px;
}

.btn-iniciar-cpf-cnpj {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 18px;
  font-size: 14px;
}

.header .icono-mensaje {
  width: 80px;
  height: 80px;
  background-color: var(--color-azul-nbc);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header .icono-mensaje i {
  font-size: 38px;
  color: var(--color-amarillo-nbc);
}

/*Contenido*/

.section-home {
  position: relative;
  overflow: hidden;
  /* background: #f7f7f7 0% 0% no-repeat padding-box; */
  padding: 50px 0px;
}

.section-separator {
  padding: 50px 0px;
}

.section-fgts {
  /* padding: 70px 0px; */
  padding: 100px 0px;
}

.border-section {
  border: 1px solid #f1f1f1;
}

.border-card {
  border: 1px solid #f1f1f1;
}

.border-accordion {
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.border-arquivos {
  border-bottom: 1px solid #b7b7b7;
}

.img-form {
  max-width: 600px;
  width: 100%;
}

.section-home .img-vertical {
  max-width: 300px;
}

.form-list-item {
  max-width: 135px;
}

@keyframes loadingCircle {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingRedirect {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

/* .loading-circle {
  animation: loadingCircle 1s ease-out infinite;
  border: 0.5em solid var(--color-azul-nbc-bright);
  border-bottom: 0.5em solid var(--color-azul-nbc);
  border-right: 0.5em solid var(--color-azul-nbc);
  border-radius: 100%;
} */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--color-azul-nbc);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-redirect {
  animation: loadingRedirect 11s ease-out;
  height: 0.5em;
  width: 100%;
  background: black;
}

.number-font {
  font-size: 45px;
  font-weight: bolder;
  color: var(--color-azul-nbc);
}

.number-font-list-group {
  list-style-type: none;
}

.number-font-list {
  font-weight: bolder;
  color: #354774;
}

.nbc-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.tab-rounded .nav-pills .nav-link,
.tab-rounded .nav-pills .form-list-item,
.tab-rounded .nav-tabs .nav-link,
.tab-rounded .nav-tabs .nav-form-list-item {
  border-radius: 25px;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-link {
  color: white;
}

.section-home .nav-pills .nav-link {
  padding: 8px 25px;
  margin-right: 10px;
  color: var(--carousel-color);
}

.section-home .nav-pills .nav-link:hover,
.nav-tabs .nav-link:hover {
  background: var(--carousel-color);
  color: var(--color-azul-nbc);
}

.section-home .nav-pills .active,
.nav-tabs .active {
  background-color: #fff;
  color: var(--color-azul-nbc);
  font-weight: 500;
}

.section-home .tab-content {
  margin-bottom: 35px;
}

.section-home .card-servicios-container {
  display: flex;
}

.section-home .btn-card-carousel {
  background-color: var(--color-azul-nbc);
  color: #fff;
  border-radius: 50%;
  width: var(--btn-card-carousel-height);
  height: var(--btn-card-carousel-width);
  text-align: center;
}

.section-home .btn-card-carousel:hover {
  background-color: #5a6899;
}

.section-home .card-servicios {
  border-radius: 8px;
  min-height: 200px;
  max-height: 250px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-servicios-link {
  position: absolute;
  bottom: min(5%, 20px);
}

.section-home .img-tarjeta {
  width: 100%;
  max-width: 80px;
}

.section-home .img-ventajas-clientes {
  width: 100%;
}

.hide {
  display: none;
}

.container-card-carousel-info {
  top: -100px;
}

.container-card-carousel-info .alice-carousel {
  position: relative;
}

.container-card-carousel-info .alice-carousel .alice-carousel__prev-btn div,
.container-card-carousel-info .alice-carousel .alice-carousel__next-btn div {
  display: flex;
  justify-content: center;
  margin: 25px 0;
}

.container-card-carousel-info .alice-carousel .alice-carousel__prev-btn div p,
.container-card-carousel-info .alice-carousel .alice-carousel__next-btn div p {
  padding: 25px;
  position: static;
}

.img-carousel-info {
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  max-height: 350px;
}

.i-width {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.mt-card-carousel-negativo {
  margin-top: calc(var(--btn-card-carousel-height) / 2 * -1);
}

/* Make Carousel Buttons Rounded */
.carousel-indicators [data-bs-target] {
  background-color: white;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  /*  width: 10px;
  height: 10px; */
  margin-right: 10px;
}

.carousel-indicators [data-bs-target]:active,
.carousel-indicators [data-bs-target]:focus {
  outline: none;
}

/* Putting the buttons below the carousel */
.carousel-indicators {
  position: absolute;
  bottom: 30px;
  /* bottom: -10px; */
}

.carousel-control-prev {
  position: absolute;
  top: 10px;
}

.card-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 1px #d2d2d2;
  border-radius: 8px;
  padding: 35px 25px;
}

.rounded-carousel-button {
  width: 20px;
  height: 20px;
}

.form-container {
  width: 100%;
  position: relative;
}

.form-container > img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.form-layout {
  top: 0;
  left: 0;
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: 0;
}

.form-inner-layout {
  background: #354774;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.form-conditions-checkbox {
  width: 35px;
  height: 35px;
  aspect-ratio: 1 / 1;
  background: transparent;
  transition: 0.3s ease-in-out;
}

.form-conditions-checkbox:hover {
  background: var(--color-amarillo-nbc);
  color: #000;
}

.input-custom {
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #9e9c9c;
}

.input-custom::placeholder {
  color: #ccc;
  font-family: "contisans";
  font-size: clamp(0.5rem, 0.8rem, 1.2rem);
}

/*Footer*/
.footer {
  /* padding: 50px 0px; */
  padding-bottom: 50px;
  background-color: var(--color-azul-nbc-shadow);
}

.footer .footer-logo {
  width: 160px;
}

.footer .icono-whatsapp {
  font-size: 35px;
}

.footer .iconos-redes-sociales {
  font-size: 35px;
}

.object-fit-cover {
  object-fit: cover;
}

.img-horizontal {
  width: 100%;
  object-fit: cover;
}

.img-directors {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.img-max-height {
  max-height: 500px;
  max-width: 100%;
  object-fit: contain;
}

.img-max-height-comercio {
  width: 100%;
  object-fit: cover;
}

/*Accordion*/
.accordion-button {
  padding: 20px 30px;
}

.accordion-button:focus,
.accordion-button:active,
.accordion-button:not(.collapsed) {
  background-color: #fff;
  color: var(--color-azul-nbc);
  border-style: none;
  box-shadow: none;
}

.accordion-item {
  border: none;
}

.accordion-button::after {
  background-image: url("../img/icon-plus-accordion.svg");
}

.accordion-button:not(.collapsed)::after {
  /* background-image: url("../img/minus-sign.png"); */
  background-image: url("../img/icon-minus-accordion.svg");
}

.card-rate {
  border-radius: 12px;
}

.card-rate-item {
  position: relative;
  width: 80%;
}

.number-rate {
  font-weight: bolder;
  font-size: 65px;
}

.rate-image {
  position: absolute;
  width: 100px;
  right: 0;
  top: 5px;
}

.hgroup-rate {
  position: relative;
}

.respetar-saltos {
  white-space: break-spaces;
  line-height: 2;
}

.border-radius {
  border-radius: 12px;
}

.border-radius-xl {
  border-radius: 37px;
}

.tab-career .nav-link {
  color: #959598;
}

.tab-career .nav-title {
  position: relative;
}

.tab-career .nav-title::before {
  position: absolute;
  content: "";
  background-color: var(--color-azul-nbc);
  width: 10px;
  height: 10px;
  border-radius: 50px;
  bottom: -20px;
  right: calc(50% - 5px);
}

textarea {
  resize: none;
}

.career-tab-content {
  min-height: 700px;
}

.no-decoration {
  text-decoration: none;
}

.info-card-list {
  list-style: none;
  padding: 0;
}

.info-card-list li {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px #a8a8a8 solid;
}

.info-card-list li:first-child {
  border-top: 1px #a8a8a8 solid;
}

.table-info-card thead tr:first-child {
  text-align: center;
}

.table-info-card thead tr:last-child {
  border-bottom: 1px #a8a8a8 solid;
}

.table-info-card tbody tr td {
  border-bottom: 1px #a8a8a8 solid;
}

.table-info-card tbody tr td div {
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
}

.list-card-img {
  width: 100%;
  height: 200px;
  max-width: 300px;
  max-height: 200px;
  object-fit: scale-down;
}

.list-card {
  border: 1px solid #f0f5ff;
  border-radius: 8px;
  transition: 0.1s;
}

.list-card:hover {
  box-shadow: #d7d8e1 0px 4px 4px;
}

.card-cookie {
  position: fixed;
  bottom: 50px;
  width: 100%;
  z-index: 1051;
}

.card-cookie .card {
  border: 1px solid rgb(200, 218, 237);
}

/* START */

.arrow-container {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrow-container .up,
.arrow-container .down {
  background: #ebeced;
  padding: 10px;
  text-align: center;
  position: absolute;
  color: var(--color-azul-nbc);
}

.arrow-container .up {
  bottom: 300px;
}

.arrow-container .down {
  top: 300px;
}

.arrow-container .up::before {
  content: "";
  position: absolute;
  background: var(--color-amarillo-nbc);
  width: 15px;
  height: 15px;
  left: calc(50% - 5px);
  top: calc(100% - 5px);
  border-radius: 50%;
}

.arrow-container .up::after {
  content: "";
  top: 100%;
  width: 5px;
  height: 100px;
  background: var(--color-amarillo-nbc);
  left: 50%;
  position: absolute;
}

.arrow-container .down::before {
  content: "";
  position: absolute;
  background: var(--color-amarillo-nbc);
  width: 15px;
  height: 15px;
  left: calc(50% - 5px);
  bottom: calc(100% - 5px);
  border-radius: 50%;
}

.arrow-container .down::after {
  content: "";
  bottom: 100%;
  width: 5px;
  height: 100px;
  background: var(--color-amarillo-nbc);
  left: 50%;
  position: absolute;
}

#pointer {
  z-index: 1;
  margin-right: 15px;
  width: 85%;
  height: 60px;
  position: relative;
  background: var(--color-azul-nbc);
  display: flex;
  justify-content: center;
  align-items: center;
}

#pointer:before {
  content: "";
  position: absolute;
  right: -30px;
  bottom: 0;
  width: 1px;
  height: 1px;
  border-left: 30px solid var(--color-azul-nbc);
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

#pointer:after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 30px solid #fff;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

/* END */

.contact .form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: #d6e1a4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(202, 213, 25, 0.5);
}

.contact .btn-primary {
  color: #000;
  background-color: #b8cb63;
  border-color: #b4c85a;
}

/* HEAD TEAM */
.timeline {
  transform: translateX(0%);
  border-left: 2px dotted #fff;
  margin-left: 30%;
  border-radius: 0 5px 5px 0;
  background-color: transparent;
  list-style: none;
}

.timeline h1 {
  font-weight: 300;
  font-size: 1.4em;
}

.timeline > .event:last-child > p {
  margin-bottom: -12px;
}
.timeline > .event:first-child > p {
  margin-top: -5px;
}

/* .timeline h2,
.timeline h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
} */

.timeline .event {
  /* border-bottom: 2px solid #cccccc; */
  border-bottom: none;
  padding-bottom: 25px;
  margin-bottom: 25px;
  /* margin-left: 50px; */
  position: relative;
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  /* left: -230px; */
  left: -230px;
  color: var(--color-amarillo-nbc);
  content: attr(data-date);
  font-family: "contisans";
  font-size: clamp(1rem, 1.1rem, 1.5rem);
  text-align: left;
  font-weight: 400;
  /* font-size: 0.9em; */
}

.timeline .event:after {
  left: -20px;
  background-color: var(--color-amarillo-nbc);
  border-radius: 50%;
  height: 15px;
  width: 15px;
  content: "";
  top: 5px;
}

.rtl .timeline {
  border-left: 0;
  text-align: right;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 3px solid #f1f1f1;
}

.logo-conti {
  width: 150px;
  height: 100%;
  object-fit: cover;
}

.rtl .timeline .event::before {
  left: 0;
  right: -170px;
}

.rtl .timeline .event::after {
  left: 0;
  right: -55.8px;
}

.form-style small {
  font-size: 15px;
}

h6,
h5 {
  font-family: "contisans-light";
  line-height: 1.5;
  font-size: 15px;
}

/* input[type="date"]::-webkit-calendar-picker-indicator { */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

/* input[type="date"] */
input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  /* Para Safari y algunos navegadores de iOS */
}

.outline-none {
  outline: none;
}

/* For bigger screen sizes */
@media (min-width: 90em) {
  .modal-card-container {
    position: relative;
  }

  .h-30rem {
    height: 30rem;
  }

  .modal-card-container .card-cpf-cnpj {
    border-radius: 0.5em;
  }

  .card-rate-item {
    position: relative;
    width: 20%;
  }

  /* We use important to overwrite the scripts style */
  #careers-carousel .alice-carousel .alice-carousel__wrapper {
    padding-left: 25% !important;
    padding-right: 25% !important;
  }
}

@media (min-width: 75em) {
  .container-header-footer {
    padding: 0 8em;
  }

  .container-governanza {
    width: 75%;
  }

  .border-bottom-title {
    display: block;
    margin-top: 10px;
    width: 70px;
    height: 3px;
    background-color: var(--color-amarillo-nbc);
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    position: absolute;
    z-index: 999;
  }
  .dropend > .dropdown-menu {
    top: -100px;
  }
  .dropdown .dropdown-menu {
    display: none;
  }

  .translate-top {
    transform: translateY(-50px);
  }

  .translate-top-100 {
    transform: translateY(-80px);
  }

  .btn-warning-link {
    min-width: 140px;
  }

  /* We use important to overwrite the scripts style */
  #careers-carousel .alice-carousel .alice-carousel__wrapper {
    padding-left: 350px !important;
    padding-right: 350px !important;
  }

  .header-titulo {
    font-size: 60px;
  }

  .parrafos {
    font-size: 20px;
  }

  .header .navbar-logo {
    width: 150px;
  }

  .form-layout {
    position: absolute;
    padding: 2%;
  }

  .dropdown-menu {
    background-color: #fff;
  }

  .dropdown-item {
    color: #000;
  }

  .header .navbar-items-container {
    position: static;
    top: 0;
    right: 0;
    /* width: calc(100% - 200px); */
    width: calc(100% - 40%);
    height: 100%;
    overflow: unset;
    padding: 0px;
    z-index: 9;
    transition: all 0.5s;
    transform: translateX(0%);
    text-align: end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
  }

  .header .navbar-nav {
    justify-content: space-between;
    align-items: center;
  }

  .header .nav-item {
    transform: translateX(0);
    height: 100%;
    font-size: 17px;
    display: flex;
    align-items: center;
  }

  /* .header-section {
    padding-top: 400px;
    padding-bottom: 100px;
  } */

  .header .card-cpf-cnpj {
    margin-top: 10px;
    position: absolute;
    width: 600px;
    right: 0;
  }

  .header .btn-iniciar-cpf-cnpj {
    font-size: 18px;
  }

  .header .card-cpf-cnpj h6 {
    font-size: 18px;
  }
}

@media (max-width: 62.0625em) {
  .timeline {
    max-width: 500px;
    padding: 0px 7px;
    margin-left: 0%;
  }

  .timeline .event:after {
    left: -15px;
    background-color: var(--color-amarillo-nbc);
    border-radius: 50%;
    height: 15px;
    width: 15px;
    content: "";
    top: 5px;
  }

  .timeline .event {
    padding-top: 30px;
  }

  .timeline .event:before {
    left: 20px;
    text-align: left;
  }

  /* .carousel-inner {
    height: 90vh;
  } */

  .carousel-item {
    height: 100%;
  }

  .footer {
    padding: 20px;
  }
}

.w-lg-33 {
  width: 100%;
}

.w-lg-25{
  width: 100%;
}

.w-lg-15 {
  width: 100%;
}

.w-lg-20 {
  width: 100%;
}

@media (min-width: 61.9375em) {
  .w-lg-50 {
    max-width: 48%;
  }
  .w-lg-20 {
    width: 20%;
  }

  .w-lg-33 {
    width: 30%;
  }

  .w-lg-25{
    width: 25%;
  }

  .w-lg-15 {
    width: 18%;
  }

  .timeline {
    transform: translateX(30%);
  }

  .img-background-advantages {
    background-image: url("../img/chica.png");
    background-repeat: no-repeat;
    background-position: 40%;
    background-size: contain;
  }

  .img-icon-advantage {
    width: 60px;
    object-fit: cover;
  }

  .grid-fgts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .card-contato {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  /* .timeline .event:after {
    left: -57.5px;
  } */

  .mt-100 {
    margin-top: 100px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: inline-block;
  }

  /* input[type="date"] */
  input[type="date"] {
    appearance: inherit;
    -webkit-appearance: inherit;
    /* Para Safari y algunos navegadores de iOS */
  }
}

@media (min-width: 64em) {
  .home-header-modal-card {
    position: absolute;
  }
  .gap-agencias {
    gap: 40px;
  }

  .min-h-65 {
    min-height: 65vh;
  }

  .img-max-height-comercio {
    height: 600px;
    object-fit: cover;
  }

  .translate-card-cambio {
    transform: translate(-7%, 0%);
  }

  .padding-card-cambio {
    padding: 60px;
  }

  .card-cambio-separator {
    padding: 70px 20px 70px 0px;
  }

  .mb-100 {
    margin-bottom: 100px;
  }

  .container__warning {
    padding: 10px 20px;
  }

  .section-home {
    /* padding: 100px 0px; */
    padding: 50px 0px;
  }

  .section-separator {
    padding: 50px 0px;
  }

  .background-history {
    padding: 100px 0px;
  }

  .btn-warning-link {
    margin-top: 0;
  }

  .container-header-title {
    text-align: start;
    height: 50%;
    /* padding: 100px 0px; */
  }

  .container-card-carousel-info .alice-carousel .alice-carousel__prev-btn,
  .container-card-carousel-info .alice-carousel .alice-carousel__next-btn {
    position: absolute;
    right: -20%;
    top: 25%;
    width: min-content;
    margin: 0 1% 0;
  }

  .container-card-carousel-info
    .alice-carousel
    .alice-carousel__prev-btn
    div
    p {
    margin: 0 100px;
  }

  /* We use important to overwrite the scripts style */
  #careers-carousel .alice-carousel .alice-carousel__wrapper {
    padding-left: 250px !important;
    padding-right: 250px !important;
  }

  /* Careers Carousel Buttons */
  #careers-carousel .alice-carousel .alice-carousel__prev-btn-item,
  #careers-carousel .alice-carousel .alice-carousel__next-btn-item {
    position: absolute;
    top: 50%;
    left: 48%;
    right: 50%;
  }

  #careers-carousel .alice-carousel .alice-carousel__prev-btn-item {
    left: 24%;
  }

  #careers-carousel .alice-carousel .alice-carousel__next-btn-item {
    left: 71%;
  }

  .card-rate-item {
    position: relative;
    width: 25%;
  }

  /* .scroll-up-btn {
    margin-right: 55px;
    margin-bottom: 50px;
  } */
  .scroll-up-btn {
    margin-right: 10px;
    /* margin-bottom: 50px; */
  }

  .about-us-header-section {
    padding: 200px 0 50px;
  }

  .about-us-header-section-inner {
    padding: 50px 0;
  }

  .number-font {
    font-size: 95px;
  }
}

/* HEAD TEAM */

@media (min-width: 36em) {
  .header .navbar-logo {
    width: 168px;
  }

  .dropend > .dropdown-menu {
    padding: 5px;
    background-color: #fff;
    position: absolute;
    transform: translate3d(80px, 0px, 0px) !important;
  }

  .card-agencias {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  .home-header-item {
    min-height: 95vh;
  }

  .header-titulo {
    font-size: 48px;
  }

  .parrafos {
    font-size: 20px;
  }

  .text-columns {
    column-count: 2;
  }

  .section-home .img-vertical {
    max-width: 500px;
  }

  .section-home .card-servicios {
    min-width: 100%;
    width: 100%;
    max-width: 350px;
    min-height: 200px;
    max-height: 200px;
  }

  .card-rate-item {
    position: relative;
    width: 50%;
  }
}
