.container-super {
  /* max-height: 400px; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 720px;
  /* min-height: 80vh; */
  /* max-height: auto; */
  border-radius: 25px;
}

.container-superpose-general {
  width: 100%;
  padding: 50px 0px;
  position: relative;
  overflow: hidden;
}

.container-superpose-image {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.container-superpose-text {
  padding: 0px 30px 30px 30px;
  height: auto;
}

figure {
  margin: 0;
}

.img-phone-horizontal {
  position: relative;
  display: block;
  height: 500px;
  left: -70%;
  /* left: -90%; */
  object-fit: cover;
}

.superpose-img {
  position: relative;
  top: -30px;
  display: block;
  margin: 0px auto;
  object-fit: cover;
  max-width: 550px;
  right: 70px;
  /* right: 175px; */
}

.container-redirects{
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0px;
}

/* @media screen and (min-width: 320px) {
  .superpose-img {
    max-width: 570px;
  }
  .img-phone-horizontal{
    left: -60%;
  }
}
 */
@media screen and (min-width: 400px) {
  .superpose-img {
    /* right: 185px; */
    /* right: 150px; */
    right: 80px;
    max-width: 600px;
  }

  .img-phone-horizontal {
    left: -50%;
  }
}

@media screen and (min-width: 768px) {
  .superpose-img {
    /* max-width: 850px; */
    top: -70px;
    max-width: 1200px;
    right: 158px;
  }

  .img-phone-horizontal {
    height: 500px;
    /* left: 0%; */
    left: -20px;
  }
  .container-superpose-image,
  .container-superpose-text {
    width: 100%;
  }

  /* .container-redirects{
    width: 100%;
    position: absolute;
    bottom: 0px;
  } */
}

@media screen and (min-width: 1025px) {
  .superpose-img {
    /* right: 100px; */
    right: 80px;
    max-width: 800px;
  }

  .container-superpose-general{
    padding: 100px 0px;
  }

  .container-superpose-text {
    padding: 30px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container-superpose-image,
  .container-superpose-text {
    width: 50%;
  }

  .container-super {
    min-height: 469px;
    position: relative;
  }
}

